
import {defineComponent, ref, onMounted, computed} from 'vue';
import BaseTabs from '@/components/ui/BaseTabs.vue';
import SearchPage from '@/components/SearchPage.vue';
import { useRoute, useRouter } from 'vue-router';
import SearchParts from '@/components/search-layouts/SearchParts.vue';
import routerService from '@/services/routerService';

export default defineComponent({
  name: 'PageSearchWrapper',
  components: {
    SearchPage,
    SearchParts,
    BaseTabs
  },
  setup() {
    const route = useRoute()

    const searchPageTabs = ref([
      { name: 'Поиск артикулов', query: 'articles'},
      { name: 'Поиск з/ч по авто', query: 'parts'}
    ])

    const getCurrentTabQuery = computed(() => route.query && route.query.search)

    return { searchPageTabs, getCurrentTabQuery }
  }
})
