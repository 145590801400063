
import {defineComponent, ref, onMounted, computed} from 'vue';
import CustomSelectModal from '@/components/ui/CustomSelectModal.vue';
import { Categories, Status } from '@/components/types/enums';
import country from '@/services/country';
import vehicleBrands from '@/services/vehicleBrands';
import Spinner from '@/components/Spinner.vue';
import vehicleModels from '@/services/vehicleModels';
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';
import articles from '@/services/articles';
import timeService from '@/services/timeService';
import BaseTable from '@/components/ui/BaseTable.vue';

export default defineComponent({
  name: 'SearchParts',
  components: {
    Spinner,
    CustomSelectModal,
    BaseCheckbox,
    BaseTable
  },
  setup() {
    const parts = ref(null) as any
    const isStatusPreparing = ref(false)
    const isLoading = ref(true)
    const sameModels = ref([]) as any
    const selectedOption = ref({
      category: null,
      country: [],
      brand: null,
      model: [],
      modify: null
    }) as any

    const dataOptions = ref({
      countries: [],
      categories: Object.values(Categories),
      brands: [],
      models: [],
      modifications: []
    })

    const allCountries = ref(null) as any
    const allBrands = ref(null) as any
    const allModels = ref(null) as any
    const allModifications = ref(null) as any

    async function getModification() {
      const ids = selectedOption.value.model
        .map((model: any) => model && model.id)
        .join(',')

      const vehicles = await vehicleModels.getModelsByIds(ids)
      const foundedAllModifications = [...new Set(vehicles.map((vehicle: any) => vehicle.desc.modBodyNum))]
      //@ts-ignore
      dataOptions.value.modifications = foundedAllModifications
      allModifications.value = foundedAllModifications
    }

    async function selectOption(option: any, field: string) {
      if (option === 'CLEAR_SELECTED') {
        selectedOption.value[field] = null
        return;
      }

      if (field === 'country') {
        selectedOption.value.country.push(option)
        return;
      }

      if (field === 'model') {
        selectedOption.value.model.push(option)
        await getModification()
        return;
      }

      selectedOption.value[field] = option

      if (field === 'brand') {
        const models = await vehicleModels.getModelsByBrand(option.id, 'ALL')

        allModels.value = models.reduce((acc: any, element: any) => {
          if (acc.findIndex((e: any) => e.model === element.model) < 0) {
            acc.push(element)
          } else {
            sameModels.value.push(element)
          }
          return acc
        }, [])

        dataOptions.value.models = allModels.value
      }
    }

    function searchOption(event: any, type: string) {
      if (type === 'COUNTRY') {
        dataOptions.value.countries = allCountries.value
        dataOptions.value.countries = dataOptions.value.countries.filter((country: string) => country.toLowerCase().includes(event.target.value.toLowerCase()))
      }
      if (type === 'VEHICLE_BRAND') {
        dataOptions.value.brands = allBrands.value
        dataOptions.value.brands = dataOptions.value.brands.filter((currentBrand: any) => currentBrand.brand.toLowerCase().includes(event.target.value.toLowerCase()))
      }
      if (type === 'VEHICLE_MODEL') {
        dataOptions.value.models = allModels.value
        dataOptions.value.models = dataOptions.value.models.filter((currentModel: any) => currentModel.model.toLowerCase().includes(event.target.value.toLowerCase()))
      }
      if (type === 'VEHICLE_MODBODYNUM') {
        dataOptions.value.modifications = allModifications.value
        dataOptions.value.modifications = dataOptions.value.modifications.filter((currentModify: any) => currentModify.toLowerCase().includes(event.target.value.toLowerCase()))
      }
    }

    function setPreparingStatus() {
      isStatusPreparing.value = !isStatusPreparing.value
    }

    function deleteCountry(countryName: string) {
      const index = selectedOption.value.country.findIndex((country: any) => country === countryName)
      selectedOption.value.country.splice(index, 1)
    }

    async function deleteModel(modelName: string) {
      const index = selectedOption.value.model.findIndex((model: any) => model === modelName)
      selectedOption.value.model.splice(index, 1)
      await getModification()
    }

    const getRequestPayloadForGettingParts = computed(() => {
      let models = [] as any
      let foundBrand = null
      let category = null as any

      if (selectedOption.value.category) {
        Object
          .entries(Categories)
          .map((currentCategory: any) => {
            const [key, value] = currentCategory
            if (value === selectedOption.value.category) {
              category = key
            }
          })
      }

      if (selectedOption.value.model) {
        models = [
          ...selectedOption.value.model.map((model: any) => model),
          ...sameModels.value.filter((sameModel: any) => {
            return sameModel.model === selectedOption.value.model.model
          })
        ]
      }

      if (selectedOption.value.brand) {
        foundBrand = allBrands.value.find((brand: any) => {
          return brand.id === selectedOption.value.brand.id
        })
      }

      models.map((model: any) => {
        console.log('MODEL!', model)
        return model && model.id
      })

      return {
        countries: [...selectedOption.value.country],
        categories: category ? [category] : [],
        ...(
          foundBrand && foundBrand.id
            ? { brands: [foundBrand && foundBrand.id] }
            : { brands: [] }
        ),
        ...(
          selectedOption.value && selectedOption.value.modify
            ? { modifications: [selectedOption.value.modify] }
            : { modifications: [] }
        ),
        models: [...models.map((model: any) => model && model.id)],
        preparing: isStatusPreparing.value
      }
    })

    const tableHeader = computed(() => {
      return [
        {
          title: 'Страна',
          content: (item: any) => item.vehicle.model.brand.country || '-'
        },
        {
          title: 'Марка',
          content: (item: any) => item.vehicle.model.brand.brand
        },
        {
          title: 'Модель',
          content: (item: any) => item.vehicle.model.model
        },
        {
          title: 'Товарная группа',
          content: (item: any) => item.article.productGroup.desc
        },
        {
          title: 'Наименование',
          content: (item: any) => item.article.name
        },
        {
          title: 'Артикул',
          content: (item: any) => item.article.article
        },
        {
          title: 'Статус',
          content: (item: any) => {
            const statuses: any = Status;
            return statuses[item.article.status];
          }
        }
      ]
    })

    async function exportSearchVehicles() {
      const table = await articles.exportSearchByVehicle(getRequestPayloadForGettingParts.value)
      let date = timeService.createDateForViewFromUTCTime();
      //@ts-ignore
      const blob = new Blob([table], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `Searched_vehicles_${date}.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }


    async function getParts() {
      isLoading.value = true
      parts.value = await articles.searchPartsArticles(getRequestPayloadForGettingParts.value)
      isLoading.value = false
    }

    onMounted(async () => {
      allCountries.value = await country.getAllCountries()
      dataOptions.value.countries = allCountries.value

      allBrands.value = await vehicleBrands.getAllVehiclesBrands('ALL')
      dataOptions.value.brands = allBrands.value.sort((brandFirst: any, brandSecond: any) => {
        return brandFirst.brand.localeCompare(brandSecond.brand);
      });

      isLoading.value = false
    })

    return {
      deleteModel,
      selectedOption,
      dataOptions,
      selectOption,
      searchOption,
      allCountries,
      isLoading,
      setPreparingStatus,
      isStatusPreparing,
      getParts,
      deleteCountry,
      exportSearchVehicles,
      tableHeader,
      parts
    }
  }
})
