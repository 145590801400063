
import {defineComponent, ref, onMounted, computed} from 'vue';
import SearchDropdown from '@/components/search/SearchDropdown.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import router from "@/router";
import {useStore} from "vuex";

export default defineComponent({
  name: 'SearchPage',
  components: {
    SearchDropdown,
    BaseIcon
  },
  setup() {
    const viewedArticles = ref([]);
    const store = useStore()

    function update(item: any) {
      if (!!item) router.push({name: 'article', params: {id: item.id}})
    }

    function getViewedArticles() {
      let storage = JSON.parse(localStorage.getItem('VIEWED_ARTICLES')!);
      if (storage) viewedArticles.value = viewedArticles.value.concat(storage)
    }

    function goToArticle(item: any) {
      router.push({name: 'article', params: {id: item.articleId}})
    }

    const canUserSearchArticles = computed(() => store.getters.getAllPermissions.search_article[store.getters.getUserRole])
    onMounted(() => {
      let loggedUser = localStorage.getItem('loggedUser');
      let cid = localStorage.getItem('cid');
      if (loggedUser && cid) {
        getViewedArticles()
      } else {
        router.push('login')
      }
    });

    return {viewedArticles, update, goToArticle, canUserSearchArticles}
  }
})
